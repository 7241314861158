import ImagS from "../data/Slide 5/nurse-holding-senior-man-s-hands-sympathy.jpg";
import Sound from "../data/3-slide/audios/Piano.mp3";
import SuccessSound from "../data/3-slide/audios/Success.mp3";
import ImgQues1 from '../data/Slide 5/Question 3/Q3-1.png';
import ImgQues2 from '../data/Slide 5/Question 3/Q3-2.png';
import ImgQues3 from '../data/Slide 5/Question 3/Q3-3.png';
import ImgQues4 from '../data/Slide 5/Question 3/Q3-4.png';
import ImgAnsYes from '../data/Slide 5/Question 3/Q3-yes.png';
import ImgAnsNo from '../data/Slide 5/Question 3/Q3-No.png';
import Fade from 'react-reveal/Fade';
import { Howl, Howler } from "howler";
import ReactAudioPlayer from "react-audio-player";
import React, { createRef } from "react";
import { Link } from "react-router-dom";
import GiSpeaker from "../data/audio-Reference/speaker.png";
import GiSpeakerOff from "../data/audio-Reference/speaker-mute.png";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import {MuteContext} from './../context/useContext';

export default class Fifth extends React.Component {
  static contextType = MuteContext;
  state = {
    questionOfSlide: "Does tumor location impact your treatment choice ?",
    muting: false,
    defaultDisplay: true,
    answeringYes: false,
    answeringNo: false,
  };
  setMuting = () => {
    this.setState({
      ...this.state,
      muting: !this.state.muting,
    });
  };
  SoundClickPlay = (src) => {
    const sound = new Howl({
      src,
    });
    sound.play();
  };
  setAnsweringYes = () => {
    this.setState({
      ...this.state,
      answeringYes: true,
      answeringNo: false,
      defaultDisplay: false
    });
    this.SoundClickPlay(SuccessSound);
  };
  setAnsweringNo = () => {
    this.setState({
      ...this.state,
      answeringNo: true,
      answeringYes: false,
      defaultDisplay: false
    });
    this.SoundClickPlay(SuccessSound);
  };

  render() {
    Howler.volume(0.3);
    return (
      <MuteContext.Consumer>
      {value =>
      <div className="">
        <img
          className="w-screen h-screen  object-cover fixed inset-0"
          src={ImagS}
          alt=""
        />
        <div className="fixed w-full h-full inset-0 bg-black opacity-80 z-30"></div>
        <div className="pt-10">
          <div className="relative bg-gradient-to-r from-[#FFFFFF] to-[#C2D4EE] w-full py-3 text-center text-lg xl:text-4xl 2xl:text-5xl text-[#254668] z-40">
            A TRUE PATIENT STORY
          </div>
          <div className="relative bg-[#50A2D4] w-full py-1 text-center mt-4 text-lg xl:text-3xl font-TitilliumWeb text-white z-40">
            Question slide
          </div>
          <div className="relative w-[90%] mx-[5%] lg:w-[80%] lg:mx-[10%] flex flex-col lg:flex-row md:items-start md:justify-around flex-nowrap mt-5 lg:mt-5 2xl:mt-5 z-50">
            <div className="flex flex-col relative w-[95%] md:w-[50%] mx-auto xl:mx-0 lg:w-[50%] xl:w-[60%] md:mt-0 xl:mt-2 md:pt-14 lg:pt-40 xl:pt-20 lg:h-[300px]">
              <div className="flex flex-col relative w-full xl:w-[80%] mr-[10%] bg-[#0E131A] opacity-80">
                  <h5 
                  className="relative text-[#4E91C0] text-sm xl:text-2xl ml-5 font-TitilliumWeb font-semibold">Question</h5>
                  <h5 className="relative text-white text-sm xl:text-2xl mt-2 mb-5 ml-5 font-semibold">{this.state.questionOfSlide}</h5>
              </div>
              <div className="flex flex-row justify-around items-center relative w-full xl:w-[80%] mt-5 mr-[10%] bg-[#0E131A] opacity-80">
                  { this.state.answeringYes === false ?
                      <h5 
                      onClick={() => this.setAnsweringYes()}
                      className={`relative w-[150px] xl:w-[220px] text-center text-white border border-[#4E91C0] text-sm xl:text-2xl mt-2 mb-2 py-1 font-semibold hover:bg-gray-700 transition-all duration-300 rounded-xl cursor-pointer ${this.state.answeringYes === true ? 'cursor-not-allowed' : null}`}>Yes</h5>
                      :
                      <h5 
                      className="relative w-[150px] xl:w-[220px] text-center text-white border border-[#046937] bg-[#046937] text-sm xl:text-2xl mt-2 mb-2 py-1 font-semibold transition-all duration-300 rounded-xl pointer-events-none">Yes</h5>
                  }
                  { this.state.answeringNo === false ?
                      <h5 
                      onClick={() => this.setAnsweringNo()}
                      className={`relative w-[150px] xl:w-[220px] text-center text-white border border-[#4E91C0] text-sm xl:text-2xl mt-2 mb-2 py-1 font-semibold hover:bg-gray-700 transition-all duration-300 rounded-xl cursor-pointer ${this.state.answeringNo === true ? 'cursor-not-allowed' : null}`}>No</h5>
                      :
                      <h5 
                      className="relative w-[150px] xl:w-[220px] text-center text-white border border-[#046937] bg-[#046937] text-sm xl:text-2xl mt-2 mb-2 py-1 font-semibold transition-all duration-300 rounded-xl pointer-events-none">No</h5>
                  }
              </div>
            </div>
            <div className="relative w-[320px] h-[230px] xl:w-[420px] xl:h-[300px] flex justify-center mx-auto items-center z-30">
              {this.state.answeringYes === true ? 
                <Fade>
                  <img className="absolute w-full h-full mt-5 lg:mt-28 xl:mt-10 top-0 left-0 object-cover z-30" src={ImgAnsYes} alt="" />
                </Fade>
                :
                null
              }
              { this.state.answeringNo === true ?
                <Fade>
                  <img className="absolute w-full h-full mt-5 lg:mt-28 xl:mt-10 top-0 left-0 object-cover z-30" src={ImgAnsNo} alt="" />
                </Fade>
                :
                null
              }
              { this.state.defaultDisplay === true ?
                <>
                  <Fade className="w-full" delay={2000}>
                    <img className="absolute w-full h-full mt-5 lg:mt-28 xl:mt-10 top-0 left-0 object-cover z-30" src={ImgQues1} alt="" />
                  </Fade>
                  <Fade className="w-full" delay={3000}>
                    <img className="absolute w-full h-full mt-5 lg:mt-28 xl:mt-10 top-0 left-0 object-cover z-30" src={ImgQues2} alt="" />
                  </Fade>
                  <Fade className="w-full" delay={4000}>
                    <img className="absolute w-full h-full mt-5 lg:mt-28 xl:mt-10 top-0 left-0 object-cover z-30" src={ImgQues3} alt="" />
                  </Fade>
                  <Fade className="w-full" delay={5000}>
                    <img className="absolute w-full h-full mt-5 lg:mt-28 xl:mt-10 top-0 left-0 object-cover z-30" src={ImgQues4} alt="" />
                  </Fade>
                </>
                :
                null
              }
              
            </div>
          </div>
          {/* for big device */}
          <div className="w-full bg-black opacity-70 py-1 fixed bottom-4 flex z-50">
            <div className="flex flex-row justify-between items-center mb-2 mt-2 w-[90%] mx-[5%] lg:w-[80%] lg:mx-[10%] z-50 relative">
              <div className="flex">
                  <Link to="/Fourth">
                  <a className="px-4 py-1 text-xs md:px-4 md:py-1 md:text-lg border border-white text-white font-medium rounded-xl">Back</a>
                  </Link>
              </div>
              <div className="flex items-center gap-3 overflow-hidden">
                  <img className="px-2 py-2 h-[30px] md:h-[40px] md:px-3 md:py-2 border border-white rounded-xl cursor-pointer" 
                      src={value.mainMute === true ? GiSpeakerOff : GiSpeaker} alt="" 
                      onClick={() => value.allMute()}
                  />
                  <Tooltip title="Put any reference you want" position="top" trigger="mouseenter">
                      <div className="flex">
                          <a className="px-3 py-1 md:px-4 md:py-1 border border-white text-sm md:text-xl text-white font-medium rounded-xl">R</a>
                      </div>
                  </Tooltip>
              </div>
              <div className="flex">
                  <Link to="/Sixth">
                  <a className="px-4 py-1 text-xs md:px-4 md:py-1 md:text-lg border border-white text-white font-medium rounded-xl">Next</a>
                  </Link>
              </div>
            </div>
          </div>
          <div className="fixed bottom-0 bg-[#4E91C0] w-full h-[20px] z-40"></div>
        </div>
      </div>
      }</MuteContext.Consumer>
    );
  }
}