import ImagS from '../data/2-slide/images/bg-old-man.jpg';
import PainImg from '../data/2-slide/images/slide 2-02.jpg';
import BannerOne from '../data/banner-png/inside-01-b.png';
import BannerTwo from '../data/banner-png/fusia-01-b.png';
import BannerThree from '../data/banner-png/yellow-01-b.png';
import BannerFour from '../data/banner-png/cyan-01-b.png';
import Sound from '../data/2-slide/audio/all-audios.mp3'
import ReactAudioPlayer from 'react-audio-player';
import Fade from 'react-reveal/Fade';
import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillCaretDown } from 'react-icons/ai';
import GiSpeaker from '../data/audio-Reference/speaker.png';
import GiSpeakerOff from '../data/audio-Reference/speaker-mute.png';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import {MuteContext} from './../context/useContext';

export default class Second extends React.Component {
    static contextType = MuteContext;
    state = {
      muting: false,
    }
    setMuting = () => {
      this.setState({
        ...this.state,
        muting: !this.state.muting
      })
    }

    render() {
      return (
        <MuteContext.Consumer>
        {value => 
          <div className="relative font-TitilliumWeb w-full h-screen pt-8 overflow-hidden">
          <ReactAudioPlayer
            className="absolute"
            src={Sound}
            autoPlay
            onLoadedMetadata
            muted={value.mainMute}
          />
          <img
            className="w-screen h-screen  object-cover fixed inset-0"
            src={ImagS}
            alt=""
          />
          <div className="fixed w-full h-full inset-0 bg-black opacity-80 z-10"></div>
          <div className="relative bg-gradient-to-r from-[#FFFFFF] to-[#C2D4EE] w-full py-3 text-center text-lg xl:text-4xl 2xl:text-5xl text-[#254668] z-20">
            A TRUE PATIENT STORY
          </div>
          <div className="relative bg-[#50A2D4] w-full py-1 text-white text-center mt-4 text-lg xl:text-3xl z-20">
            Karim is a 60 years old male
          </div>
          <div className="relative w-[90%] mx-[5%] lg:w-[80%] lg:mx-[10%] mt-8 xl:text-2xl text-white z-20">
            <Fade delay={3500} >
              <div className="text-lg xl:text-2xl 2xl:text-3xl">A school teacher who will soon</div>
            </Fade>
            <Fade delay={5500} >
            <div className=" text-lg xl:text-2xl 2xl:text-3xl mt-[2px]">become a grandfather</div>
            </Fade>
          </div>
          <div className="flex flex-col lg:flex-row md:justify-around md:items-center w-[90%] mx-[5%] lg:w-[80%] lg:mx-[10%] md:mx-auto justify-around relative mt-5 lg:mt-20 xl:mt-12 2xl:mt-20 z-20">
            <Fade delay={7500}>
              <div className="flex flex-row w-[90%] md:w-[60%] lg:w-[155%] xl:w-[60%] mx-auto h-[150px] lg:h-[200px] xl:h-[250px] 2xl:h-[300px] md:mt-[0px] lg:mt-0 bg-[#0E131A] opacity-50 ">
                <div className="w-[30%] lg:w-[40%] h-full">
                  <img className="w-full h-full rounded-ms object-cover" src={PainImg} alt="" />
                </div>
                <div className="flex flex-col w-[70%] lg:w-[60%] h-full">
                  <h2 className="text-lg lg:text-2xl 2xl:text-4xl text-white text-center mt-1 xl:mt-10 mb-0">One morning Karim woke up</h2>
                  <AiFillCaretDown className="text-center text-[#BB292E] text-7xl mx-auto mb-0" />
                  <h5 className="text-center text-[#BB292E] text-lg lg:text-2xl 2xl:text-4xl font-medium mb-0 lg:mb-2">an unbearable</h5>
                  <h5 className="text-center text-white text-lg lg:text-2xl 2xl:text-4xl mb-2 mt-1 pb-2 pt-1 align-middle border-t-[1px] border-b-[1px] border-[#BB292E]">abdominal pain</h5>
                </div>
              </div>
            </Fade>
            <div className="relative flex justify-center items-center w-[90%]  xl:w-[30%] mx-auto h-[200px] md:h-[300px] xl:h-[150px]">
              <Fade className="w-full h-[200px] md:h-[300px] xl:h-[150px]" delay={12200}>
                  <img className="absolute top-0 left-0 w-[150%] h-[150%] mt-[-40px] md:mt-[-60px] lg:mt-[-60px] xl:w-[400%] xl:h-[400%] xl:mt-[-210px] object-cover" src={BannerOne} alt="" />
                </Fade>
              <div className="absolute w-full h-full top-0 left-0 origin-center animate-bannerRotate ">
                <Fade className="w-full h-[200px] md:h-[300px] xl:h-[150px]" delay={13000}>
                  <img className="absolute top-0 left-0 w-[150%] h-[150%] mt-[-40px] md:mt-[-60px] lg:mt-[-60px] xl:w-[400%] xl:h-[400%] xl:mt-[-210px] object-cover" src={BannerTwo} alt="" />
                </Fade>
                <Fade className="w-full h-[200px] md:h-[300px] xl:h-[150px]" delay={14200}>
                  <img className="absolute top-0 left-0 w-[150%] h-[150%] mt-[-40px] md:mt-[-60px] lg:mt-[-60px] xl:w-[400%] xl:h-[400%] xl:mt-[-210px] object-cover" src={BannerThree} alt="" />
                </Fade>
                <Fade className="w-full h-[200px] md:h-[300px] xl:h-[150px]" delay={16600}>
                  <img className="absolute top-0 left-0 w-[150%] h-[150%] mt-[-40px] md:mt-[-60px] lg:mt-[-60px] xl:w-[400%] xl:h-[400%] xl:mt-[-210px] object-cover" src={BannerFour} alt="" />
                </Fade>
              </div>
            </div>
          </div>
          {/* for big device */}
          <div className="w-full bg-black opacity-70 py-1 fixed bottom-4 flex z-50">
            <div className="flex flex-row justify-between items-center mb-2 mt-2 w-[90%] mx-[5%] lg:w-[80%] lg:mx-[10%] z-50 relative">
              <div className="flex">
                  <Link to="/">
                  <a className="px-4 py-1 text-xs md:px-4 md:py-1 md:text-lg border border-white text-white font-medium rounded-xl">Back</a>
                  </Link>
              </div>
              <div className="flex items-center gap-3 overflow-hidden">
                  <img className="px-2 py-2 h-[30px] md:h-[40px] md:px-3 md:py-2 border border-white rounded-xl cursor-pointer" 
                      src={value.mainMute === true ? GiSpeakerOff : GiSpeaker} alt="" 
                      onClick={() => value.allMute()}
                  />
                  <Tooltip title="Put any reference you want" position="top" trigger="mouseenter">
                      <div className="flex">
                          <a className="px-3 py-1 md:px-4 md:py-1 border border-white text-sm md:text-xl text-white font-medium rounded-xl">R</a>
                      </div>
                  </Tooltip>
              </div>
              <div className="flex">
                  <Link to="/Third">
                  <a className="px-4 py-1 text-xs md:px-4 md:py-1 md:text-lg border border-white text-white font-medium rounded-xl">Next</a>
                  </Link>
              </div>
            </div>
          </div>
          <div className="fixed bottom-0 bg-[#4E91C0] w-full h-[20px] z-40"></div>
      </div>
    }</MuteContext.Consumer>
  );
  }
}

/*

        
        
        
        
      
      
      */