import ImagS from "../data/Slide 6/casual-senior-man-home-with-doctor.jpg";
import Sound from "../data/3-slide/audios/Piano.mp3";
import SuccessSound from "../data/3-slide/audios/Success.mp3";
import ImgQues from '../data/Slide 6/image 3 no buttons.png';
import ImgAns from '../data/Slide 6/A-image.png';
import VideoAns from '../data/Slide 6/1874-slide17-24-v5L.mp4';
import Fade from 'react-reveal/Fade';
import { Howl, Howler } from "howler";
import ReactAudioPlayer from "react-audio-player";
import React, { createRef } from "react";
import { Link } from "react-router-dom";
import GiSpeaker from "../data/audio-Reference/speaker.png";
import GiSpeakerOff from "../data/audio-Reference/speaker-mute.png";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import {MuteContext} from './../context/useContext';

export default class Sixth extends React.Component {
  static contextType = MuteContext;
  state = {
    questionOfSlide: "Does turnor location impact your treatment choice ?",
    muting: false,
    viewImage: false,
    viewInfo: false,
    viewVideo: false
  };
  setMuting = () => {
    this.setState({
      ...this.state,
      muting: !this.state.muting,
    });
  };
  SoundClickPlay = (src) => {
    const sound = new Howl({
      src,
    });
    sound.play();
  };
  setViewImage = () => {
    this.setState({
      ...this.state,
      viewImage: true,
      viewInfo: false,
      viewVideo: false
    });
    this.SoundClickPlay(SuccessSound);
  };
  setViewInfo = () => {
    this.setState({
      ...this.state,
      viewImage: false,
      viewInfo: true,
      viewVideo: false
    });
    this.SoundClickPlay(SuccessSound);
  };
  setViewVideo = () => {
    this.setState({
      ...this.state,
      viewImage: false,
      viewInfo: false,
      viewVideo: true
    });
    this.SoundClickPlay(SuccessSound);
  };

  render() {
    Howler.volume(0.3);
    return (
      <MuteContext.Consumer>
      {value =>
      <div className="">
        <img
          className="w-screen h-screen  object-cover fixed inset-0"
          src={ImagS}
          alt=""
        />
        <div className="fixed w-full h-full inset-0 bg-black opacity-80 z-30"></div>
        <div className="pt-10 overflow-hidden">
          <div className="relative bg-gradient-to-r from-[#FFFFFF] to-[#C2D4EE] w-full py-3 text-center text-lg xl:text-4xl 2xl:text-5xl text-[#254668] z-40">
            A TRUE PATIENT STORY
          </div>
          <div className="relative bg-[#50A2D4] w-full py-1 text-center mt-4 text-lg xl:text-3xl font-TitilliumWeb text-white z-40">
            Karim is a 60 years old male
          </div>
          <div className="relative w-[90%] mx-[5%] lg:w-[80%] lg:mx-[10%] flex flex-col items-center lg:flex-row lg:items-start md:justify-around flex-nowrap mt-5 lg:mt-5 2xl:mt-5 mb-24 lg:mb-0 z-50">
            <div className="w-[90%] md:w-[45%] lg:w-[50%] mt-5 ">
                <div className="relative mx-auto w-[300px] h-[321px] 2xl:w-[450px] 2xl:h-[481.5px]">
                    <img className="absolute top-0 left-0" src={ImgQues} alt="" />
                    <div className="absolute top-0 right-0 w-[135px] h-[200px] 2xl:w-[202.5px] 2xl:h-[300px] flex flex-col z-50">
                        <button onClick={() => this.setViewImage()} className="mt-[20px] w-[135px] py-[5px] 2xl:mt-[40px] 2xl:w-[202.5px] 2xl:py-[7.5px] border-2 border-white text-white font-normal rounded-full transition-all duration-300 hover:bg-gray-700">View image</button>
                        <button onClick={() => this.setViewInfo()} className="mt-[20px] w-[135px] py-[5px] 2xl:mt-[40px] 2xl:w-[202.5px] 2xl:py-[7.5px] border-2 border-white text-white font-normal rounded-full transition-all duration-300 hover:bg-gray-700">View info</button>
                        <button onClick={() => this.setViewVideo()} className="mt-[20px] w-[135px] py-[5px] 2xl:mt-[40px] 2xl:w-[202.5px] 2xl:py-[7.5px] border-2 border-white text-white font-normal rounded-full transition-all duration-300 hover:bg-gray-700">View video</button>
                    </div>
                </div>
            </div>
            <div className="w-[90%] mx-[5%] sm:mx-0 mb-20 sm:mb-0 md:w-[55%] lg:w-[50%] mt-5 z-30">
                { this.state.viewImage === true ?
                    <Fade bottom>
                        <div className="relative flex justify-center items-center w-full h-[321px] sm:w-[400px] sm:h-[321px] 2xl:w-[600px] 2xl:h-[481.5px] bg-gray-800 opacity-95 ml-0 sm:ml-[100px] md:ml-0">
                            <img className="relative flex justify-center items-center top-0 left-0 w-[225px] h-[300px] mx-auto sm:w-[231px] sm:h-[321px] sm:mx-[84.5px] 2xl:w-[260.5px] 2xl:h-[381.5px] " src={ImgAns} alt="" />
                        </div>
                    </Fade>
                    :
                    null
                }
                {    this.state.viewInfo === true ?
                    <Fade right>
                        <div className="relative flex flex-col mx-auto sm:w-[400px] sm:h-[321px] 2xl:w-[600px] 2xl:h-[481.5px] bg-gray-800 opacity-95 overflow-y-auto">
                            <h2 className="text-xl 2xl:text-4xl text-[#50A2D4] font-medium mt-3 ml-3 ">Muscles:</h2>
                            <p className="text-lg 2xl:text-[1.98rem] 2xl:leading-normal text-white font-medium mt-3 ml-3 mr-3 font-TitilliumWeb">
                                There are about 600 muscles in
                                the human body. The three main
                                types of muscle include skeletal,
                                smooth and cardiac.
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit, sed diam
                                nonummy nibh euismod tincidunt
                                ut laoreet dolore.
                                Lorem ipsum dolor sit amet,
                                conrem ipsum dolor sit amet,
                                There are about 600 muscles in
                                the human body. The three main
                                types of muscle include skeletal,
                                smooth and cardiac.
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit, sed diam
                                nonummy nibh euismod tincidunt
                                ut laoreet dolore.
                                Lorem ipsum dolor sit amet,
                                conrem ipsum dolor sit amet.
                            </p>
                        </div>
                    </Fade>
                    :
                    null
                }
                {  this.state.viewVideo === true ?
                    <Fade bottom>
                        <div className="relative flex flex-col mx-auto sm:w-[400px] sm:h-[225px] xl:w-[600px] xl:h-[337.5px] 2xl:w-[900px] 2xl:h-[506.25px] bg-gray-800 opacity-95 overflow-y-auto">
                            <video className="w-full h-full" src={VideoAns} controls></video>
                        </div>
                    </Fade>
                    :
                    null
                }
            </div>
          </div>
          {/* for big device */}
          <div className="w-full bg-black opacity-70 fixed bottom-4 py-1 flex z-50">
            <div className="flex flex-row justify-between items-center mb-2 mt-2 w-[90%] mx-[5%] lg:w-[80%] lg:mx-[10%] z-50 relative">
              <div className="flex">
                  <Link to="/Fifth">
                  <a className="px-4 py-1 text-xs md:px-4 md:py-1 md:text-lg border border-white text-white font-medium rounded-xl">Back</a>
                  </Link>
              </div>
              <div className="flex items-center gap-3 overflow-hidden">
                  <img className="px-2 py-2 h-[30px] md:h-[40px] md:px-3 md:py-2 border border-white rounded-xl cursor-pointer" 
                      src={value.mainMute === true ? GiSpeakerOff : GiSpeaker} alt="" 
                      onClick={() => value.allMute()}
                  />
                  <Tooltip title="Put any reference you want" position="top" trigger="mouseenter">
                      <div className="flex">
                          <a className="px-3 py-1 md:px-4 md:py-1 border border-white text-sm md:text-xl text-white font-medium rounded-xl">R</a>
                      </div>
                  </Tooltip>
              </div>
              <div className="flex">
                  <Link to="/Finish">
                  <a className="px-4 py-1 text-xs md:px-4 md:py-1 md:text-lg border border-white text-white font-medium rounded-xl">Next</a>
                  </Link>
              </div>
            </div>
          </div>
          <div className="fixed bottom-0 bg-[#4E91C0] w-full h-[20px] z-40"></div>
        </div>
      </div>
      }</MuteContext.Consumer>
    );
  }
}