import ImagS from "../data/3-slide/nurse.jpg";
import ChartNoBar from "../data/3-slide/chart.png";
import barOne from "../data/3-slide/slide 3-bar-1.png";
import barTwo from "../data/3-slide/slide 3-bar-2.png";
import barThree from "../data/3-slide/slide 3-bar-3.png";
import Sound from "../data/3-slide/audios/Piano.mp3";
import SoundClick from "../data/3-slide/audios/Mouse Click.mp3";
import SuccessSound from "../data/3-slide/audios/Success.mp3";
import { Howl, Howler } from "howler";
import ReactAudioPlayer from "react-audio-player";
import React, { createRef } from "react";
import { Link } from "react-router-dom";
import GiSpeaker from "../data/audio-Reference/speaker.png";
import GiSpeakerOff from "../data/audio-Reference/speaker-mute.png";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import {MuteContext} from './../context/useContext';

export default class Third extends React.Component {
  static contextType = MuteContext;
  state = {
    questionOfSlide: "What kind of pain Karim woke up with ?",
    informationAboutQuestion: "Some information you can put here",
    muting: false,
    answeringRight: false,
    answeringMigraine: false,
    answeringBackPain: false,
    isMigraineHovered: false,
    isBackPainHovered: false,
  };
  setMuting = () => {
    this.setState({
      ...this.state,
      muting: !this.state.muting,
    });
  };
  SoundClickPlay = (src) => {
    const sound = new Howl({
      src,
    });
    sound.play();
  };
  setAnsweringRight = () => {
    this.setState({
      ...this.state,
      answeringRight: !this.state.answeringRight,
    });
    this.SoundClickPlay(SuccessSound);
  };
  setAnsweringMigraine = () => {
    this.setState({
      ...this.state,
      answeringMigraine: !this.state.answeringMigraine,
    });
    this.SoundClickPlay(SoundClick);
  };
  setAnsweringBackPain = () => {
    this.setState({
      ...this.state,
      answeringBackPain: !this.state.answeringBackPain,
    });
    this.SoundClickPlay(SoundClick);
  };

  render() {
    Howler.volume(0.3)
    return (
      <MuteContext.Consumer>
      {value => 
      <div className="">
        <img
          className="w-screen h-screen  object-cover fixed inset-0"
          src={ImagS}
          alt=""
        />
        <div className="fixed w-full h-full inset-0 bg-black opacity-80 z-30"></div>
        <div className="pt-10">
          <div className="relative bg-gradient-to-r from-[#FFFFFF] to-[#C2D4EE] w-full py-3 text-center text-lg xl:text-4xl 2xl:text-5xl text-[#254668] z-40">
            A TRUE PATIENT STORY
          </div>
          <div className="relative bg-[#50A2D4] w-full py-1 text-center mt-4 text-lg xl:text-3xl font-TitilliumWeb text-white z-40">
            Question slide
          </div>
          <div className="relative w-[90%] mx-[5%] lg:w-[80%] lg:mx-[10%] flex flex-col lg:flex-row md:items-start md:justify-around flex-nowrap gap-5 md:mt-10 lg:mt-5 z-50">
            <div className="flex relative w-[250px] h-[180px] sm:w-[565px] sm:h-[400px] md:w-[565px] md:h-[400px] lg:w-[565px] lg:h-[400px] xl:h-[400px] 2xl:w-[916px] 2xl:h-[650px] mt-10 lg:mt-0 mx-auto lg:mx-0 z-40">
                <img src={this.state.answeringRight === true ? ChartNoBar : ChartNoBar } 
                    alt=""
                    className="absolute w-full sm:w-full sm:mx-auto h-full inset-0 md:object-cover 2xl:object-fill"
                />
                <div className="relative top-0 w-full h-[66%] sm:h-[68%] md:h-[71%] lg:h-[68%] xl:h-[68%] 2xl:h-[67%] sm:object-cover">
                  <img style={{transitionProperty: 'height', transitionDuration: '2000ms'}} className={`absolute bottom-[-7px] sm:bottom-[-5px] md:bottom-[7px] lg:bottom-[-7px] xl:bottom-[-7px] 2xl:bottom-[-10px] w-full sm:object-fill ${this.state.answeringRight === true ? 'h-[130px] sm:h-[265px] md:h-[253px] lg:h-[290px] xl:h-[280px] 2xl:h-[440px]' : 'h-0'}`} src={barOne} alt="" />
                  <img style={{transitionProperty: 'height', transitionDuration: '2000ms'}} className={`absolute bottom-[-7px] sm:bottom-[-5px] md:bottom-[7px] lg:bottom-[-7px] xl:bottom-[0px] 2xl:bottom-[-10px] w-full sm:object-fill ${this.state.answeringRight === true ? 'h-[120px] sm:h-[255px] md:h-[235px] lg:h-[260px] xl:h-[260px] 2xl:h-[410px]' : 'h-0'}`} src={barTwo} alt="" />
                  <img style={{transitionProperty: 'height', transitionDuration: '2000ms'}} className={`absolute bottom-[-7px] sm:bottom-[-5px] md:bottom-[7px] lg:bottom-[-7px] xl:bottom-[-7px] 2xl:bottom-[-10px] w-full sm:object-fill ${this.state.answeringRight === true ? 'h-[130px] sm:h-[240px] md:h-[260px] lg:h-[250px] xl:h-[300px] 2xl:h-[390px]' : 'h-0'}`} src={barThree} alt="" />
                </div>
            </div>
            <div className="flex flex-col relative w-[95%] md:w-[500px] mx-auto xl:mx-0 lg:w-[45%] md:mt-3 lg:mt-8 xl:mt-8 2xl:mt-16 xl:h-[400px] mb-20 lg:mb-2 overflow-hidden">
                <div className="flex flex-col relative w-full lg:w-full bg-[#0E131A] opacity-80">
                    <h5 
                    className="relative text-[#4E91C0] text-sm xl:text-2xl ml-5 font-TitilliumWeb font-semibold">Question</h5>
                    <h5 className="relative text-white text-sm xl:text-2xl mt-2 mb-5 ml-5 font-semibold">{this.state.questionOfSlide}</h5>
                </div>
                <div className="flex flex-row justify-around items-center relative w-full lg:w-full mt-5 bg-[#0E131A] opacity-80">
                    { this.state.answeringMigraine === false ?
                        <h5 
                        onClick={() => this.setAnsweringMigraine()}
                        className={`relative text-white border border-[#4E91C0] text-xs xl:text-2xl mt-2 mb-2 sm:px-5 sm:py-1 px-3 py-1 font-semibold hover:bg-gray-700 transition-all duration-300 rounded-xl cursor-pointer ${this.state.answeringRight === true ? 'pointer-events-none' : null}`}>Migraine</h5>
                        :
                        <h5 
                        className="relative text-white border border-[#BB292E] bg-[#BB292E] text-xs xl:text-2xl mt-2 mb-2 sm:px-5 sm:py-1 px-3 py-1 font-semibold transition-all duration-300 rounded-xl pointer-events-none">{ this.state.isMigraineHovered === false ? "Try again" : "Migraine"}</h5>
                    }
                    { this.state.answeringRight === false ?
                        <h5 
                        onClick={() => this.setAnsweringRight()}
                        className="relative text-white border border-[#4E91C0] text-xs xl:text-2xl mt-2 mb-2 sm:px-5 sm:py-1 px-3 py-1 font-semibold hover:bg-gray-700 transition-all duration-300 rounded-xl cursor-pointer">Abdominal</h5>
                        :
                        <h5 className="relative text-white border border-[#046937] bg-[#046937] text-xs xl:text-2xl mt-2 mb-2 sm:px-5 sm:py-1 px-3 py-1 font-semibold transition-all duration-300 rounded-xl pointer-events-none">Abdominal</h5>
                    }
                    { this.state.answeringBackPain === false ?
                        <h5 
                        onClick={() => this.setAnsweringBackPain()}
                        className={`relative text-white border border-[#4E91C0] text-xs xl:text-2xl mt-2 mb-2 sm:px-5 sm:py-1 px-3 py-1 font-semibold hover:bg-gray-700 transition-all duration-300 rounded-xl cursor-pointer ${this.state.answeringRight === true ? 'pointer-events-none' : null}`}>Back pain</h5>
                        :
                        <h5 
                        className="relative text-white border border-[#BB292E] bg-[#BB292E] text-xs xl:text-2xl mt-2 mb-2 sm:px-5 sm:py-1 px-3 py-1 font-semibold transition-all duration-300 rounded-xl pointer-events-none">{ this.state.isBackPainHovered === false ? "Try again" : "Back Pain"}</h5>
                    }
                </div>
                { this.state.answeringRight === true ?
                    <div className="flex flex-col relative w-full mt-2 mb-10 bg-[#0E131A] opacity-80">
                        <h5 
                        style={this.state.answeringRight === false ? {color: "#fff"} : {color: "#FDBC46"}}
                        className="relative text-[#4E91C0] text-sm xl:text-2xl mt-5 ml-5 font-TitilliumWeb font-semibold">Information</h5>
                        <h5 className="relative text-white text-sm xl:text-2xl mt-2 mb-5 ml-5 font-semibold">{this.state.informationAboutQuestion}</h5>
                    </div>
                    :
                    null
                }
            </div>
          </div>
          {/* for big device */}
          <div className="w-full bg-black opacity-70 py-1 fixed bottom-4 flex z-50">
            <div className="flex flex-row justify-between items-center mb-2 mt-2 w-[90%] mx-[5%] lg:w-[80%] lg:mx-[10%] z-[70] relative">
              <div className="flex">
                  <Link to="/Second">
                  <a className="px-4 py-1 text-xs md:px-4 md:py-1 md:text-lg border border-white text-white font-medium rounded-xl">Back</a>
                  </Link>
              </div>
              <div className="flex items-center gap-3 overflow-hidden">
                  <img className="px-2 py-2 h-[30px] md:h-[40px] md:px-3 md:py-2 border border-white rounded-xl cursor-pointer" 
                      src={value.mainMute === true ? GiSpeakerOff : GiSpeaker} alt="" 
                      onClick={() => value.allMute()}
                  />
                  <Tooltip title="Put any reference you want" position="top" trigger="mouseenter">
                      <div className="flex">
                          <a className="px-3 py-1 md:px-4 md:py-1 border border-white text-sm md:text-xl text-white font-medium rounded-xl">R</a>
                      </div>
                  </Tooltip>
              </div>
              <div className="flex">
                  <Link to="/Fourth">
                  <a className="px-4 py-1 text-xs md:px-4 md:py-1 md:text-lg border border-white text-white font-medium rounded-xl">Next</a>
                  </Link>
              </div>
            </div>
          </div>
          <div className="fixed bottom-0 bg-[#4E91C0] w-full h-[20px] z-40"></div>
        </div>
      </div>
      }</MuteContext.Consumer>
    );
  }
}
