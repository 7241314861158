import React, { useState } from 'react';
import './styles/index.css';
import Home from '../src/components/Home';
import Second from '../src/components/second';
import Third from '../src/components/third';
import Fourth from '../src/components/fourth';
import Fifth from '../src/components/fifth';
import Sixth from '../src/components/sixth';
import Finish from '../src/components/finish';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {MuteContext} from './context/useContext';

function App() {

  const [mainMute, setMainMute] = useState(false);
  const [mainVolume, setMainVolume] = useState(1);

  const allMute = () => {
    if(mainMute === false) {
      setMainMute(true);
    } else {
      setMainMute(false);
    }
    if(mainVolume === 1) {
      setMainVolume(0);
    } else {
      setMainVolume(1);
    }
  }

  return (
    <MuteContext.Provider value={{mainMute, mainVolume, setMainMute, setMainVolume, allMute}}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/Second"  >
            <Second />
          </Route>
          <Route path="/Third">
            <Third/>
          </Route>
          <Route path="/Fourth">
            <Fourth />
          </Route>
          <Route path="/Fifth">
            <Fifth />
          </Route>
          <Route path="/Sixth">
            <Sixth />
          </Route>
          <Route path="/Finish">
            <Finish />
          </Route>
        </Switch>
      </Router>
    </MuteContext.Provider>
  );
}
export default App;