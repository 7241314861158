import ImagS from '../data/1-slide/slider1.jpg';
import Logo from '../data/1-slide/logo-01.png';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-router-dom';

function App() {
    return (
    <div className="w-full h-full font-TitilliumWeb">
      <img  src={ImagS} 
          className='absolute w-full h-full top-0 left-0 object-cover xl:object-cover' alt='bg' 
      />
      <div className="flex flex-row justify-start xl:justify-start items-center mt-[20px] xl:mt-[50px] ml-[20px]">
          <div className="flex justify-start">
              <Fade left delay={3000}>
                  <img  src={Logo} 
                      className='w-[150px] md:w-[200px] xl:w-full object-fill h-full' 
                      alt='company logo' 
                  />
              </Fade>
          </div>
          <div className="flex flex-row ml-[10px]">
              <Zoom  delay={3000}>
                  <div className="border-l-2 border-[#254668] h-[80px] md:h-[100px] xl:h-[100px]"></div>
              </Zoom>
              <div className="flex flex-col justify-center items-start">
                  <Fade right  delay={3000}>
                      <div 
                          className="font-droid text-[#254769] text-sm md:text-2xl xl:text-5xl 
                          font-bold ml-[10px]"
                      >
                          Karim 
                      </div>
                      <div className="font-droid text-[#254769] text-sm md:text-2xl xl:text-[30px] ml-[10px]">
                          A true patient story
                      </div>
                  </Fade>
              </div>
              
          </div>
      </div>
      <div className="absolute bottom-0 bg-[#4E91C0] w-full h-[20px] z-30"></div>
      <Zoom>
          <div className="absolute flex justify-center top-[45%] w-full z-50">
              <Zoom  delay={2000}>
                  <Link to='/second'>
                      <a  className="px-6 py-2 border border-white text-white font-black text-[12px] md:text-[20px]
                          xl:text-[37px] rounded-2xl transition-all z-50 duration-300 hover:px-8 hover:py-3"
                      >
                          START
                      </a>
                  </Link>
              </Zoom>
          </div>
      </Zoom>
    </div>
    );
}

export default App;
